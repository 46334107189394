<template>
  <v-container>
    <h1 class="py-12 text-center">ご利用いただけるプランはありません</h1>
  </v-container>
</template>

<script>
export default {
  name: "PlansPricing"
};
</script>

<style scoped></style>
